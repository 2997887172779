import {SPECS} from '../../../domain/specs';
import {ReadOnlyExperiments} from '@wix/wix-experiments';
import {ICart, VeloInputs} from '../../../types/app.types';

export function wixcodePublicApi(experiments: ReadOnlyExperiments, setVeloInputs: (x: VeloInputs) => Promise<void>) {
  return {
    showSecureCheckout: async () => {
      await setVeloInputs({shouldShowSecureCheckout: true});
    },
    hideSecureCheckout: async () => {
      await setVeloInputs({shouldShowSecureCheckout: false});
    },
    validateCartBeforeCheckout: async (veloValidateFunc: (cart: ICart) => Promise<boolean>) => {
      if (experiments.enabled(SPECS.VeloValidationOnCheckout)) {
        await setVeloInputs({validationFunction: veloValidateFunc});
      }
    },
  };
}
